import type { Page, Response } from '~/type'
import type { HomeAdvertiseDto, HomeNoticeDto, JackpotDTO } from '~/type/home'
import { http } from '~/utils/http'

// 最大赢家排行
export async function getJackpotList() {
  const res = await http<Response<JackpotDTO>>('/app-api/game/maxWinnerRank', {
    method: 'POST',
    body: {}
  })
  if (res.data) {
    res.data.jackpot = Number(res.data?.jackpot)
    res.data.winnerList?.forEach((item) => {
      item.image = addHttpPrefix(item.image)
      item.imageNight = addHttpPrefix(item.imageNight)
      item.win = Number(item.win).toFixed(2)
    })
  }
  return res
}

// 跑马灯
export async function getHomeNotices(data: { isUser: boolean }) {
  return http<Response<{ content: string; remark: string }[]>>(
    '/app-api/system/revolvingLantern/list',
    {
      method: 'POST',
      body: data,
      noMsg: true
    }
  )
}

// 首页活动弹窗-今日是否不再提醒的状态
export async function queryTodayNoNotice() {
  return http<Response<{ todayNoNotice: boolean }>>(
    '/app-api/member/banner/queryTodayNoNotice',
    {
      method: 'POST',
      body: {}
    }
  )
}

// 首页活动弹窗
export async function queryBannerList(data?: { model: object }) {
  return http<Response<Page<HomeAdvertiseDto>>>('/app-api/member/banner/list', {
    method: 'POST',
    body: data
  })
}

// 首页公告弹窗-今日是否不再提醒的状态
export async function queryNoticeStatus() {
  return http<Response<{ todayNoNotice: boolean }>>(
    '/app-api/member/banner/queryTodayNoNotice',
    {
      method: 'POST',
      body: {}
    }
  )
}

// 首页公告弹窗
export async function queryNoticeList(data?: { model: object }) {
  return http<Response<HomeNoticeDto[]>>('/app-api/member/notice/indexNotice', {
    method: 'POST',
    body: data
  })
}
